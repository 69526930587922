/**
* @define TextContent
*/

.TextContent {
  &--grey {
    background-color: var(--colour-grey-hooktext);
  }

  &--inverted {
    background-color: var(--colour-hook-inverted);
    color: white;
  }

  &-contents {
    padding-bottom: var(--gutter-xlarge);
    padding-top: var(--gutter-xlarge);

    @media (--large) {
      padding-bottom: var(--gutter-xxlarge);
      padding-top: var(--gutter-xxlarge);
    }
  }

  &-title {
    font-family: var(--font-headings);
    font-size: var(--font-size-hook);
    text-transform: uppercase;

    @media (--large) {
      font-size: var(--font-size-hook-desktop);
      padding-right: var(--gutter-xlarge);
      width: 50%;
    }
  }

  &-body {
    flex: 1 0 100%;
    line-height: 1.7em;
    margin-top: var(--gutter-large);

    /* stylelint-disable plugin/selector-bem-pattern */

    h1,
    h2,
    h3 {
      font-family: var(--font-headings);
      font-weight: normal;
      margin-top: var(--gutter-large);

      @media (--large) {
        margin-top: var(--gutter-xlarge);
      }
    }

    ul li:not(:first-child) {
      margin-top: var(--gutter-small);

      @media (--large) {
        margin-top: var(--gutter-medium);
      }
    }

    /* stylelint-enable plugin/selector-bem-pattern */

    :first-child {
      margin-top: 0 !important;
    }

    @media (--large) {
      font-size: var(--font-size-large);
    }
  }
}
