.datepicker-top-left,
.datepicker-top-right {
  border-top-color: var(--colour-primary);
}

.datepicker-top-left::before,
.datepicker-top-right::before {
  border-bottom-color: var(--colour-primary);
}

.datepicker-bottom-left,
.datepicker-bottom-right {
  border-bottom-color: var(--colour-primary);
}

.datepicker-bottom-left::before,
.datepicker-bottom-right::before {
  border-top-color: var(--colour-primary);
}
