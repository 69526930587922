/**
 * @define FileInput
 */

.FileInput {
  position: relative;

  &-input {
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 0.1px;
    z-index: -1;
  }
}

@mixin button FileInput-button {

  /* */

}

.FileInput-filename {
  display: inline-block;
  margin: 0 0 0 var(--gutter-small);
  padding: var(--button-padding) 0;
}
