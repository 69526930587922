.Svg {
	background: url("sprites.svg") no-repeat;
}

.Svg-amex-logo {
	background-position: 71.20743034055728% 38.80597014925373%;
}

.Svg-amex-logo {
	width: 53px;
	height: 21px;
}

.Svg-document-agents {
	background-position: 100% 26.200873362445414%;
}

.Svg-document-agents {
	width: 46px;
	height: 60px;
}

.Svg-document-default {
	background-position: 100% 0;
}

.Svg-document-default {
	width: 46px;
	height: 60px;
}

.Svg-document-tenants {
	background-position: 100% 52.40174672489083%;
}

.Svg-document-tenants {
	width: 46px;
	height: 60px;
}

.Svg-error-404 {
	background-position: 49.03846153846154% 50.19762845849802%;
}

.Svg-error-404 {
	width: 64px;
	height: 36px;
}

.Svg-gear-wheel {
	background-position: 75.57471264367815% 48.65900383141762%;
}

.Svg-gear-wheel {
	width: 28px;
	height: 28px;
}

.Svg-gocardless {
	background-position: 0 0;
}

.Svg-gocardless {
	width: 174px;
	height: 23px;
}

.Svg-logo-bare {
	background-position: 81.3953488372093% 70.78189300411523%;
}

.Svg-logo-bare {
	width: 32px;
	height: 46px;
}

.Svg-logo-large {
	background-position: 79.0909090909091% 0;
}

.Svg-logo-large {
	width: 156px;
	height: 104px;
}

.Svg-logo-medium {
	background-position: 0 78.89908256880734%;
}

.Svg-logo-medium {
	width: 106px;
	height: 71px;
}

.Svg-logo-small {
	background-position: 68.83116883116882% 70.78189300411523%;
}

.Svg-logo-small {
	width: 68px;
	height: 46px;
}

.Svg-logo-white-bare {
	background-position: 95.93023255813954% 95.47325102880659%;
}

.Svg-logo-white-bare {
	width: 32px;
	height: 46px;
}

.Svg-logo-white-large {
	background-position: 0 12.432432432432432%;
}

.Svg-logo-white-large {
	width: 156px;
	height: 104px;
}

.Svg-logo-white-medium {
	background-position: 39.25925925925926% 78.89908256880734%;
}

.Svg-logo-white-medium {
	width: 106px;
	height: 71px;
}

.Svg-logo-white-small {
	background-position: 0 100%;
}

.Svg-logo-white-small {
	width: 68px;
	height: 46px;
}

.Svg-mastercard-logo {
	background-position: 65.75757575757575% 48.84615384615385%;
}

.Svg-mastercard-logo {
	width: 46px;
	height: 29px;
}

.Svg-stripe {
	background-position: 0 52.049180327868854%;
}

.Svg-stripe {
	width: 153px;
	height: 45px;
}

.Svg-visa-logo {
	background-position: 54.375% 38.951310861423224%;
}

.Svg-visa-logo {
	width: 56px;
	height: 22px;
}

.Svg-zero-marker {
	background-position: 94.82758620689656% 75.9493670886076%;
}

.Svg-zero-marker {
	width: 28px;
	height: 52px;
}

