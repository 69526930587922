/**
 * @define Tooltip
 */

.Tooltip {
  display: inline-block;
  position: relative;

  &-balloon {
    font-size: var(--font-size-tiny);
    left: 50%;
    min-width: 200px;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity var(--transition-default);
    width: auto;
    z-index: 1001;

    &.is-closed {
      opacity: 0;
    }

    @media (--medium) {
      font-size: var(--font-size-small);
      min-width: 400px;
    }
  }

  &-text {
    background-color: var(--colour-brand);
    border-radius: var(--border-radius);
    box-shadow: 0 0 0 3px color(var(--colour-grey-mid) a(30%));
    color: white;
    display: inline-block;
    padding: var(--gutter-small);
    position: relative;
    transform: translateX(-50%) translateY(calc(-100% - 7px));

    &::after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #4d4d4c;
      bottom: -5px;
      content: "";
      display: block;
      height: 0;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 0;
    }
  }

  &--bottom {
    .Tooltip-balloon {
      bottom: 0;
      top: auto;
    }

    .Tooltip-text {
      transform: translateX(-50%) translateY(calc(100% + 7px));

      &::after {
        border-bottom: 5px solid #4d4d4c;
        border-top: 0;
        bottom: auto;
        top: -5px;
      }
    }
  }

  &--left {
    .Tooltip-balloon {
      left: 0;
    }

    .Tooltip-text {
      transform: translateY(calc(-100% - 7px));

      &::after {
        left: 10px;
      }
    }
  }

  &--right {
    .Tooltip-balloon {
      left: auto;
      right: 0;
      text-align: right;
    }

    .Tooltip-text {
      transform: translateY(calc(-100% - 7px));

      &::after {
        left: auto;
        right: 0;
      }
    }
  }

  /* stylelint-disable plugin/selector-bem-pattern */

  &--left&--bottom &-text,
  &--right&--bottom &-text {
    transform: translateY(calc(100% + 7px));
  }

  /* stylelint-enable plugin/selector-bem-pattern */
}
