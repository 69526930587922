/**
 * @define MainNav
 */

.MainNav {
  background-color: white;
  color: var(--colour-brand);
  flex: 0 0 auto;
  overflow: visible;
  position: relative;
  transition: var(--transition-default) color, var(--transition-default) background-color;
  z-index: 10;

  &-contents {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &-logo-open { display: none; }

  &-toggler {
    cursor: pointer;
    font-family: var(--font-headings);
    text-transform: uppercase;
    transition: var(--transition-default) color;

    &:hover {
      color: var(--colour-primary);

      html.zd-theme-agents & {
        color: var(--colour-agents);
      }

      html.zd-theme-tenants & {
        color: var(--colour-tenants);
      }
    }
  }

  &-open { display: block; }

  &-close { display: none; }

  &.is-open {
    background-color: var(--colour-brand);
    color: white;

    @media (--large) {
      background-color: transparent;
      color: var(--colour-brand);
    }

    .MainNav-logo-closed { display: none; }

    .MainNav-logo-open { display: block; }

    .MainNav-open { display: none; }

    .MainNav-close { display: block; }
  }

  &-icon {
    color: var(--colour-primary);
    display: inline-block;
    font-size: 30px;
    margin-left: var(--gutter-smaller);
    transform: translateY(2px);

    html.zd-theme-agents & {
      color: var(--colour-agents);
    }

    html.zd-theme-tenants & {
      color: var(--colour-tenants);
    }
  }

  &-list {
    background-color: var(--colour-brand);
    display: none;
    font-size: 0;
    height: auto;
    left: 0;
    list-style-type: none;
    padding-left: 0;
    position: absolute;
    right: 0;
    top: 100%;

    @media (--large) {
      background-color: transparent;
      left: unset;
      position: relative;
      right: unset;
      top: unset;
    }

    .MainNav.is-open & {
      display: block;
    }

    li {
      display: block;
      font-family: var(--font-headings);
      font-size: var(--font-size-normal);
      margin-top: 0 !important;
      position: relative;
      text-transform: uppercase;

      &:not(:last-child)::before {
        background-color: var(--colour-grey-dark);
        bottom: 0;
        content: "";
        height: 1px;
        left: var(--gutter);
        position: absolute;
        right: 0;
      }

      @media (--large) {
        display: inline-block;
        font-size: var(--font-size-small);
        margin: 0;
        padding: var(--gutter);

        &::before {
          display: none;
        }
      }
    }

    a {
      color: white !important;
      display: block;
      padding: var(--gutter-large);
      position: relative;
      text-decoration: none;
      transition: var(--transition-default) color;
      vertical-align: center;

      &:hover {
        color: var(--colour-primary) !important;

        html.zd-theme-agents & {
          color: var(--colour-agents) !important;
        }

        html.zd-theme-tenants & {
          color: var(--colour-tenants) !important;
        }
      }

      @media (--large) {
        color: var(--colour-brand) !important;
        height: 35px;
        padding: 0;

        &::before {
          background-color: rgba(0, 0, 0, 0);
          bottom: 0;
          content: "";
          left: 0;
          position: absolute;
          right: -var(--gutter);
          top: 0;

          @media (--large) {
            right: -var(--gutter-large);
          }
        }

        &::after {
          background-color: var(--colour-primary);
          bottom: 0;
          content: "";
          height: 3px;
          left: 0;
          position: absolute;
          transition: var(--transition-default) width;
          width: 0%;
        }

        html.zd-theme-agents & {
          color: var(--colour-agents);

          &::after {
            background-color: var(--colour-agents);
          }
        }

        html.zd-theme-tenants & {
          color: var(--colour-tenants);

          &::after {
            background-color: var(--colour-tenants);
          }
        }
      }
    }

    a.is-current,
    a:hover {
      color: color(var(--colour-primary) lightness(50%)) !important;

      @media (--large) {
        &::after {
          width: 100%;
        }
      }

      html.zd-theme-agents & {
        color: var(--colour-agents) !important;
      }

      html.zd-theme-landlords & {
        color: color(var(--colour-primary) lightness(50%)) !important;
      }

      html.zd-theme-tenants & {
        color: var(--colour-tenants) !important;
      }
    }
  }

  @media (--large) {
    &-list {
      display: block;
    }

    &-open,
    &-close {
      display: none !important;
    }
  }

  &--pinned {
    background-color: var(--colour-brand);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateY(-200%) translateZ(0);
    transition: var(--transition-default) transform;

    .MainNav-toggler {
      color: white;
    }

    .MainNav-logo-open {
      display: block;
    }

    .MainNav-contents {
      padding-bottom: var(--gutter-smaller);
      padding-top: var(--gutter-smaller);
    }

    .MainNav-list {
      height: calc(100vh - 56px);
      overflow: auto;
    }

    @media (--large) {
      .MainNav-list {
        height: auto;
        overflow: hidden;

        a {
          color: white !important;
          height: auto;

          &::before,
          &::after {
            display: none !important;
          }
        }
      }
    }
  }

  &--opened {
    transform: translateY(0) translateZ(0);
  }

  &--forced {
    transition: none;
  }
}
