/**
* @define HowItWorks
*/

.HowItWorks {
  &--grey {
    background-color: var(--colour-grey-hooktext);
  }

  &-contents {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: var(--gutter-large);
    padding-top: var(--gutter-xxlarge);

    &:not(:first-child) {
      padding-top: var(--gutter-large);
    }

    &:last-child {
      @media (--medium) {
        padding-bottom: var(--gutter-xxlarge);
      }
    }

    @media (--medium) {
      flex-wrap: none;
      padding-bottom: var(--gutter-large);
      padding-top: var(--gutter-xxlarge);
    }
  }

  &-title {
    flex: 1 0 100%;
    font-family: var(--font-headings);
    font-size: var(--font-size-hook);
    text-transform: uppercase;

    @media (--medium) {
      font-size: var(--font-size-hook-desktop);
    }
  }

  &-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: -var(--gutter);
    margin-right: -var(--gutter);
    overflow-x: auto;
    padding: var(--gutter-large) var(--gutter);
    width: 100vw;

    @media (--medium) {
      align-items: center;
      flex-direction: row-reverse;
      margin-left: 0;
      margin-right: 0;
      overflow: hidden;
      padding: 0;
      width: 100%;
    }

    &-title {
      flex: 0 0 70%;
      font-size: var(--font-size-hook);
      line-height: 1em;
      margin-right: var(--gutter-large);
      max-width: 350px;
      padding-right: var(--gutter);
      position: relative;
      text-transform: uppercase;

      &::before {
        background-color: var(--colour-primary);
        bottom: 0;
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 2px;
      }

      @media (--medium) {
        flex-basis: 50%;
        font-size: var(--font-size-hook-desktop);
        margin-right: 0;
        max-width: unset;
        padding: 0 0 0 var(--gutter-xlarge);

        &::before {
          bottom: -var(--gutter);
          left: 0;
          right: unset;
          top: -var(--gutter);
        }
      }

      html.zd-theme-agents &::before {
        background-color: var(--colour-agents) !important;
      }

      html.zd-theme-tenants &::before {
        background-color: var(--colour-tenants) !important;
      }
    }

    &:nth-child(even) &-title {
      @media (--medium) {
        padding: 0 var(--gutter-xlarge) 0 0;

        &::before {
          left: unset;
          right: 0;
        }
      }
    }

    &:not(:first-child) {
      margin-top: var(--gutter-large);

      @media (--medium) {
        margin-top: var(--gutter-xlarge);
      }
    }

    &-list {
      align-items: flex-start;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;

      &-item {
        flex: 0 0 80vw;
        max-width: 450px;
        padding: 0 var(--gutter-large) 0 0;

        @media (--medium) {
          flex-basis: auto;
          padding: 0;

          &:not(:first-child) {
            margin-top: var(--gutter-large);
          }
        }

        &-number {
          display: inline-block;
          font-family: var(--font-headings);
          font-size: var(--font-size-large);

          @media (--medium) {
            display: block;
            font-size: var(--font-size-large-desktop);
          }
        }

        &-title {
          display: inline-block;
          font-family: var(--font-headings);
          text-transform: uppercase;

          @media (--medium) {
            display: block;
          }
        }

        &-copy {
          margin-top: var(--gutter);
        }
      }

      @media (--medium) {
        flex: 0 0 50%;
        flex-direction: column;
        padding: 0 var(--gutter-xlarge) 0 0;
      }
    }

    &:nth-child(even) &-list {
      @media (--medium) {
        padding: 0 0 0 var(--gutter-xlarge);
      }
    }

    &:nth-child(even) {
      @media (--medium) {
        flex-direction: row;
      }
    }
  }
}
