/**
 * @define FormGroup
 */

.FormGroup {
  display: block;

  &-label {
    border-bottom: var(--colour-brand) 2px solid;
    color: var(--colour-brand);
    font-family: var(--font-headings);
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-headings);
    padding: 0 0 var(--gutter-small);
    position: relative;
    text-transform: uppercase;
  }

  &-edit-button {
    bottom: var(--gutter-small);
    position: absolute;
    right: 0;
  }

  &-contents {
    padding: var(--gutter-xlarge) var(--gutter-large);
  }

  &-flex-row {
    align-items: top;
    display: flex;
    justify-content: space-around;
  }
}
