/**
* @define Faqs
*/

.Faqs {
  &--grey {
    background-color: var(--colour-grey-hooktext);
  }

  &-contents {
    padding-bottom: var(--gutter-xlarge);
    padding-top: var(--gutter-xlarge);

    @media (--large) {
      padding-bottom: var(--gutter-xxlarge);
      padding-top: var(--gutter-xxlarge);
    }
  }

  &-title {
    font-family: var(--font-headings);
    font-size: var(--font-size-hook);
    text-transform: uppercase;

    @media (--large) {
      flex-basis: 50%;
      font-size: var(--font-size-hook-desktop);
      padding-right: var(--gutter-xlarge);
    }
  }

  &-blurb {
    margin-top: var(--gutter);

    @media (--large) {
      font-size: var(--font-size-large);
      margin-top: var(--gutter-large);
    }
  }

  &-faqs {
    font-size: var(--font-size-normal);
    margin-top: var(--gutter);
    width: 100%;

    @media (--large) {
      font-size: var(--font-size-large);
      margin-top: var(--gutter-large);
    }
  }
}
