/**
 * @define NavigationList
 */

.NavigationList {
  position: relative;

  &-button {
    @mixin buttonSizing;

    @mixin buttonInteraction;

    background-color: white;
    border: var(--colour-grey-light) 1px solid;
    border-radius: var(--border-radius-button);
    box-sizing: border-box;
    display: inline-block;
    min-width: var(--navigation-list-width);
    padding: var(--button-padding-small);
    position: relative;
    transition: background-color var(--transition-default), border-color var(--transition-default);
    user-select: none;
    vertical-align: bottom;

    @media (--medium) {
      min-width: 300px;
    }

    @media (--large) {
      min-width: 300px;
    }

    &:not(:last-child) {
      margin-right: var(--gutter-small);
    }

    /* state */

    &:hover {
      border-color: var(--colour-grey-mid);
    }

    &::after {
      @mixin icon;

      content: var(--icon-down);
      font-size: 0.8em;
      position: absolute;
      right: var(--gutter-small);
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &--dark &-button {
    background-color: var(--colour-grey-dark);
    border: 0;
    color: white;

    &:hover {
      background-color: var(--colour-grey-mid-dark);
    }
  }

  &-items {
    display: none;
    list-style-type: none;
    min-width: var(--navigation-list-width);
    position: absolute;
    top: calc(100% + var(--gutter));
    z-index: 10;

    &.is-open {
      display: block;
    }

    &::after {
      border-bottom: 15px solid var(--colour-grey-lighter);
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      content: "";
      display: block;
      height: 0;
      position: absolute;
      right: var(--gutter);
      top: -15px;
      width: 0;
    }

    li {
      margin: 0 !important;
    }

    li a {
      background-color: var(--colour-grey-lighter);
      color: var(--colour-grey-dark);
      display: block;
      padding: var(--button-padding-small);
      text-decoration: none;
      transition: all var(--transition-default);

      &:hover {
        background-color: var(--colour-grey-light);
      }
    }

    li:first-child {
      border-radius: var(--border-radius-button) var(--border-radius-button) 0 0;
      overflow: hidden;
    }

    li:last-child {
      border-radius: 0 0 var(--border-radius-button) var(--border-radius-button);
      overflow: hidden;
    }
  }
}
