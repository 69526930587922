/**
* @define CookieBar
*/

.CookieBar {
  background-color: var(--colour-brand-dark);
  bottom: 0;
  color: white;
  flex: 2 0 auto;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 100;

  &-contents {
    margin: 0 auto;
    max-width: var(--width-large);
    padding: var(--gutter);

    @media (--large) {
      padding: var(--gutter);
    }
  }

  a {
    color: color(var(--colour-primary) lightness(50%));
    text-decoration: none;
    transition: var(--transition-default) color;

    html.zd-theme-agents & {
      color: var(--colour-agents);
    }

    html.zd-theme-landlords & {
      color: color(var(--colour-primary) lightness(50%));
    }

    html.zd-theme-tenants & {
      color: var(--colour-tenants);
    }

    &:hover {
      color: white !important;
    }
  }

  &-close {
    font-size: 20px;
    transform: translateY(-6px);

    @media (--large) {
      display: block;
      float: right;
      font-size: 26px;
      transform: translateY(-8px);
    }
  }
}
