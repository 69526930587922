/**
 * @define Input
 */

.Input {
  backface-visibility: hidden;
  display: inline-block;
  overflow: hidden;
  padding-top: 15px;
  position: relative;
  transform: translateZ(0);
  vertical-align: top;
  width: 300px;

  &.is-focused {
    transform: none;
  }

  &-field {
    font-size: 0;
    position: relative;
    transform: translateZ(0);
  }

  &-input {
    @mixin inputSimple;

    appearance: none;
    border: 0;
    border-bottom: 1px var(--colour-grey-mid) solid;
    border-radius: 0;
    caret-color: var(--colour-primary);
    color: var(--colour-brand);
    cursor: pointer;
    font-family: var(--font-body);
    font-size: var(--font-size-input);
    line-height: 1;
    margin: 0;
    min-height: var(--input-min-height);
    padding: var(--gutter-small) 0 var(--gutter-small);
    position: relative;
    width: 100%;

    &:focus {
      cursor: text;
      outline: none;
    }

    &::placeholder {
      color: var(--colour-grey-mid);
      transition: transform 0.3s ease, opacity 0.3s ease;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      box-shadow: 0 0 0 1000px white inset;
    }

    html.zd-theme-tenants & {
      caret-color: var(--colour-tenants);
    }

    html.zd-theme-agents &:not(:disabled) {
      border-bottom-color: var(--colour-agents);
      caret-color: var(--colour-agents);
    }

    html.zd-theme-lld &:not(:disabled) {
      border-bottom-color: var(--colour-lld);
      caret-color: var(--colour-lld);
    }
  }

  &:not(&--present) &-input:disabled {
    color: var(--colour-input-disabled);
  }

  &-input[type='date']::-webkit-clear-button,
  &-input[type='date']::-webkit-inner-spin-button {
    display: none;
  }

  &-input[type='date']::-webkit-calendar-picker-indicator {
    margin: -1px;
  }

  &-label {
    color: var(--colour-grey-mid);
    display: block;
    font-size: var(--font-size-small);
    left: 0;
    line-height: 1;
    opacity: 0;
    position: absolute;
    top: -12px;
    transform: translateY(5px);
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  &-border {
    backface-visibility: hidden;
    background-color: var(--colour-grey-dark);
    bottom: 0;
    display: block;
    height: 2px;
    left: 0;
    pointer-events: none;
    position: absolute;
    transform: scaleX(0) translateZ(0);
    transform-origin: 0 0;
    transition: all 0.2s ease-out;
    width: 100%;

    html.zd-theme-tenants & {
      background-color: var(--colour-tenants);
    }

    html.zd-theme-agents & {
      background-color: var(--colour-agents);
    }

    html.zd-theme-lld & {
      background-color: var(--colour-lld);
    }
  }

  &-input:invalid {
    box-shadow: none;
  }

  &-input:focus::placeholder {
    opacity: 0;
    transform: translateY(-5px);
  }

  &-input:not(:disabled):hover ~ &-border {
    background-color: var(--colour-grey-dark);
    transform: scaleX(1) translateZ(0);

    html.zd-theme-agents & {
      background-color: var(--colour-agents);
    }

    html.zd-theme-tenants & {
      background-color: var(--colour-tenants);
    }

    html.zd-theme-lld & {
      background-color: var(--colour-lld);
    }
  }

  &-input:not(:disabled):focus ~ &-border,
  &-input:not(:disabled).is-focused ~ &-border {
    background-color: var(--colour-primary);
    transform: scaleX(1) translateZ(0);

    html.zd-theme-agents & {
      background-color: var(--colour-agents);
    }

    html.zd-theme-tenants & {
      background-color: var(--colour-tenants);
    }

    html.zd-theme-lld & {
      background-color: var(--colour-lld);
    }
  }

  &--fixed-label .Input-label {
    opacity: 1;
    transform: translateY(0);
    white-space: nowrap;
  }

  &.is-focused &-label,
  &-input:focus ~ &-label {
    color: var(--colour-primary);
    opacity: 1;
    transform: translateY(0);

    html.zd-theme-agents & {
      color: var(--colour-agents);
    }

    html.zd-theme-tenants & {
      color: var(--colour-tenants);
    }

    html.zd-theme-lld & {
      color: var(--colour-lld);
    }
  }

  &-input:not(:placeholder-shown):not(.StripeElement) + &-label {
    opacity: 1;
    transform: translateY(0);
  }

  /* helpers (errors and hint text) */

  &-helpers {
    backface-visibility: hidden;
    font-size: var(--font-size-small);
    letter-spacing: -0.02em;
    position: relative;
    transform: translateZ(0);

    &::after {
      border-radius: 0 0 var(--border-radius) var(--border-radius);
      color: var(--colour-grey-mid);
      content: var(--unicode-nbsp);
      display: block;
      font-size: var(--font-size-small);
      left: 0;
      line-height: 1.2em;
      padding: var(--gutter-small) 0;
      pointer-events: none;
      transition: opacity var(--transition-default);
      width: 100%;
    }

    &[data-hint]:not([data-hint=''])::after {
      content: attr(data-hint);
    }

    &[data-hint]:not([data-hint='']) {
      margin-bottom: var(--gutter);
    }
  }

  &.is-error,
  html.zd-theme-tenants &.is-error,
  html.zd-theme-agents &.is-error,
  html.zd-theme-lld &.is-error {
    .Input-input {
      border-color: var(--colour-error);
      caret-color: var(--colour-error);
    }

    .Input-label {
      color: var(--colour-error);
    }

    .Input-border {
      background-color: var(--colour-error) !important;
    }

    .Input-helpers::after {
      color: var(--colour-error);
      font-family: var(--font-headings);
      font-weight: normal !important;
      text-align: right;
    }

    .Input-helpers[data-error]:not([data-error=''])::after {
      content: attr(data-error);
    }
  }

  &-hiviz {
    overflow: initial;
  }

  &-hiviz.is-error {
    .Input-helpers {
      margin-bottom: var(--gutter);
    }

    .Input-helpers::after {
      background-color: var(--colour-error);
      border-radius: var(--border-radius-toast);
      box-sizing: border-box;
      color: var(--colour-white) !important;
      font-size: var(--font-size-normal);
      padding: var(--gutter-medium);
      padding-bottom: calc(var(--gutter-medium) - 2px);
      text-align: left !important;
      transform: translateY(var(--gutter-small));
    }

    .Input-helpers::before {
      border-bottom: 5px solid var(--colour-error);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      content: '';
      display: block;
      height: 0;
      left: var(--gutter-medium);
      position: absolute;
      top: var(--gutter-smaller);
      width: 0;
    }

    .Input-helpers:not([data-error])::before,
    .Input-helpers:not([data-error])::after {
      display: none;
    }
  }

  &-dateentry-field {
    width: 25%;

    .Input-helpers::after {
      width: calc(300% + var(--gutter));
    }

    @media (--medium) {
      width: 60px;

      .Input-helpers::after {
        width: calc(220px + var(--gutter));
      }
    }

    &-year {
      width: 25%;

      @media (--medium) {
        width: 100px;
      }
    }
  }

  /* disabled state */

  &-input:disabled + &-label {
    opacity: 1;
    transform: translateY(0);
  }

  &-input:disabled + &-border {
    display: none;
  }

  &-input:disabled {
    border-style: dashed;
    cursor: not-allowed;
  }

  &--present &-input:disabled {
    border-color: var(--colour-present-border);
    color: var(--colour-brand);
    cursor: default;
    opacity: 1;
    -webkit-text-fill-color: var(--colour-brand);
  }

  /* spanning container and label positioning */

  &--span {
    flex: 2 1 auto;
    width: 100%;

    .Input-field,
    .Input-input {
      width: 100%;
    }
  }

  /* margins for series of inputs */

  &:not(:last-child):not(.u-block) {
    margin-right: var(--gutter-small);
  }
}

/* Units */

.Input {
  &[class*='Input--unit-'] {
    .Input-input {
      padding-left: calc(var(--gutter) - 5px);
    }

    .Input-field::before {
      color: var(--colour-grey-mid);
      font-family: var(--font-body);
      font-size: var(--font-size-input);
      left: 0;
      line-height: 1;
      pointer-events: none;
      position: absolute;
      top: 20px;
      transform: translateY(-50%);
      transition: color var(--transition-default);
      z-index: 2;
    }

    &:not(.is-disabled):hover .Input-field::before {
      color: var(--colour-grey-dark);
    }

    &:not(.is-disabled).is-focused .Input-field::before {
      color: var(--colour-primary);

      html.zd-theme-tenants & {
        color: var(--colour-tenants);
      }

      html.zd-theme-agents & {
        color: var(--colour-agents);
      }
    }
  }

  &--unit-percent {
    .Input-input {
      padding-left: 0 !important;
      padding-right: calc(var(--gutter) - 5px) !important;
    }
  }

  &--unit-percent &-field::before {
    content: '%';
    left: inherit !important;
    left: unset !important;
    right: 0;
  }

  &--unit-gbp &-field::before,
  &--unit-pound &-field::before {
    content: '£';
  }

  &--unit-usd &-field::before,
  &--unit-dollar &-field::before {
    content: '$';
  }

  &--unit-eur &-field::before,
  &--unit-euro &-field::before {
    content: '€';
  }
}

/* icon font handling */

.Input[class*='Icons-'] {
  .Input-input {
    padding-right: var(--gutter);
  }

  &::before {
    color: var(--colour-grey-mid);
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 34px;
    transform: translateY(-50%);
    transition: color var(--transition-default);
  }

  &:not(.is-disabled):hover::before {
    color: var(--colour-grey-dark);
  }

  &:not(.is-disabled).is-focused::before {
    color: var(--colour-primary);

    html.zd-theme-tenants & {
      color: var(--colour-tenants);
    }

    html.zd-theme-agents & {
      color: var(--colour-agents);
    }
  }
}

.Input[class*='Icons-cc-']::before {
  font-size: 1.5em;
  top: 40px;
}

.Input.Input--no-label {
  &::before {
    top: 20px;
  }
}

/* stylelint-disable plugin/selector-bem-pattern */

.Input-pikaday {
  font-family: var(--font-body) !important;

  &.pika-single {
    border-radius: var(--border-radius);
    transform: translateY(var(--gutter-small));
  }

  &.is-above {
    transform: translateY(-20px);
  }

  &.pika-single.is-bound {
    @mixin inputBoxShadow var(--colour-brand);
  }

  .is-today .pika-button {
    color: var(--colour-primary);

    html.zd-theme-tenants & {
      color: var(--colour-tenants);
    }

    html.zd-theme-agents & {
      color: var(--colour-agents);
    }
  }

  .pika-button:hover,
  .pika-row.pick-whole-week:hover .pika-button {
    background-color: var(--colour-primary);
    color: white;

    html.zd-theme-tenants & {
      background-color: var(--colour-tenants);
      color: white;
    }

    html.zd-theme-agents & {
      background-color: var(--colour-agents);
      color: white;
    }
  }

  .is-selected .pika-button {
    background-color: var(--colour-primary);
    border-radius: 3px;
    box-shadow: none;
    color: white;
    font-family: var(--font-headings);
    font-weight: normal !important;

    html.zd-theme-tenants & {
      background-color: var(--colour-tenants);
    }

    html.zd-theme-agents & {
      background-color: var(--colour-agents);
    }
  }

  .pika-label::after {
    content: '\25be';
    margin-left: 3px;
  }

  abbr {
    text-decoration: none;
  }

  .pika-button {
    text-align: center;
  }
}

/* stylelint-enable plugin/selector-bem-pattern */
