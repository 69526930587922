/**
 * @define TextReveal
 */

.TextReveal {
  cursor: pointer;
  display: block;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &-cta {
    display: block;
    font-size: var(--font-size-normal);
    padding-left: var(--gutter-large);
    position: relative;
    transition: color var(--transition-default);
    user-select: none;

    @media (--large) {
      font-size: var(--font-size-large);
    }

    &::before {
      @mixin icon;

      color: var(--colour-primary);
      content: var(--icon-expand-down);
      font-size: var(--font-size-normal);
      left: 0;
      position: absolute;
      transition: transform var(--transition-default);

      html.zd-theme-tenants & {
        color: var(--colour-tenants);
      }

      html.zd-theme-agents & {
        color: var(--colour-agents);
      }

      @media (--large) {
        font-size: var(--font-size-large);
      }
    }
  }

  &:hover &-cta {
    color: var(--colour-primary);

    html.zd-theme-tenants & {
      color: var(--colour-tenants);
    }

    html.zd-theme-agents & {
      color: var(--colour-agents);
    }
  }

  &-content-wrap {
    display: block;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }

  &-content {
    padding: var(--gutter) 0 var(--gutter) var(--gutter-large);
  }

  &.is-open {
    .TextReveal-cta::before {
      content: var(--icon-expand-up);
    }

    .TextReveal-content-wrap {
      max-height: 1000px;
      transition: max-height 0.2s ease-in;
    }
  }
}
