/**
* @define Video
*/

.Video {
  background-position: center;
  background-size: cover;

  &-contents {
    background-position: center;
    background-size: cover;
    cursor: pointer;
    height: auto;
    margin: var(--gutter) auto 80px;
    padding: 0;
    position: relative;

    @media (--medium) {
      margin: var(--gutter) auto;
    }

    @media (--large) {
      max-height: 600px;
    }
  }

  &-aspect {
    height: 0;
    padding-bottom: 56.25%;
  }

  &-container {
    align-items: flex-end;
    bottom: 0;
    display: flex;
    justify-content: flex-start;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &-cta {
    margin: var(--gutter);
  }

  &-cta-icon {
    align-items: center;
    background-color: black;
    color: var(--colour-primary);
    display: inline-flex;
    font-size: 24px;
    height: 60px;
    justify-content: center;
    width: 60px;

    html.zd-theme-agents & {
      color: var(--colour-agents) !important;
    }

    html.zd-theme-tenants & {
      color: var(--colour-tenants) !important;
    }
  }

  &-cta-text {
    bottom: -45px;
    color: white;
    display: inline-block;
    font-size: var(--font-size-large);
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;

    @media (--medium) {
      align-items: center;
      bottom: auto;
      display: inline-flex;
      height: 60px;
      justify-content: center;
      left: auto;
      padding: 0 var(--gutter);
      position: relative;
      right: auto;
      text-align: left;
    }
  }

  &-contents:hover {
    .Video-cta-icon {
      background-color: #111;
    }
  }
}
