/**
 * @define BreadcrumbList
 */

.BreadcrumbList {
  list-style-type: none;
}

.BreadcrumbList-item {
  color: var(--colour-grey-dark);
  display: inline-block;
  font-family: var(--font-headings);
  position: relative;

  & a {
    color: var(--colour-grey-mid-dark);
  }

  &:not(:last-child) {
    margin-right: 12px;
  }

  &:not(:last-child)::after {
    color: var(--colour-grey-dark);
    content: ">";
    font-size: 0.8em;
    pointer-events: none;
    position: absolute;
    right: -12px;
    top: 50%;
    transform: translateY(-50%);
  }
}
