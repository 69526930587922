/**
* @define HookText
*/

.HookText {
  &--grey {
    background-color: var(--colour-grey-hooktext);
  }

  &--inverted {
    background-color: var(--colour-hook-inverted);
    color: white;
  }

  &-contents {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: var(--gutter-xlarge);
    padding-top: var(--gutter-xlarge);

    @media (--large) {
      flex-wrap: none;
      padding-bottom: var(--gutter-xxlarge);
      padding-top: var(--gutter-xxlarge);
    }
  }

  &-title {
    flex: 1 0 100%;
    font-family: var(--font-headings);
    font-size: var(--font-size-hook);
    text-transform: uppercase;

    @media (--large) {
      flex-basis: 50%;
      font-size: var(--font-size-hook-desktop);
      padding-right: var(--gutter-xxxlarge);
    }
  }

  &-body {
    flex: 1 0 100%;
    line-height: 1.7em;
    margin-top: var(--gutter-large);

    :first-child {
      margin-top: 0;
    }

    @media (--large) {
      flex-basis: 50%;
      font-size: var(--font-size-large);
      margin-top: 0;
    }
  }

  &--two-column {
    .HookText-title {
      @media (--large) {
        flex-basis: 100%;
        padding-right: 0;
      }
    }

    .HookText-body {
      @media (--large) {
        margin-top: var(--gutter-large);
        padding-right: var(--gutter-large);
      }
    }
  }

  &--inverted {
    .HookText-body {
      font-size: var(--font-size-small);

      @media (--large) {
        font-size: var(--font-size-normal);
      }
    }
  }
}
