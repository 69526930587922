/**
* @define MarketingFooter
*/

.MarketingFooter {
  background-color: var(--colour-brand-dark);
  flex: 0 0 auto;
  height: auto;

  &-contents {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: var(--gutter-xlarge);
    padding-top: var(--gutter-xlarge);

    @media (--large) {
      flex-wrap: none;
      padding-bottom: var(--gutter-xxlarge);
      padding-top: var(--gutter-xxlarge);
    }
  }

  &-col {
    width: 100%;

    @media (--large) {
      flex: 2 0 50%;
      width: 50%;
    }
  }

  &-col-links {
    flex: 2 0 100%;

    @media (--large) {
      flex-basis: 25%;
    }
  }

  &-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    &-lower {
      flex-direction: column;

      @media (--large) {
        flex-direction: row;
      }
    }
  }

  &-social {
    display: block;
    font-size: 25px;
    margin-bottom: var(--gutter-xlarge);

    a {
      margin-right: var(--gutter-xlarge);
    }
  }

  a {
    color: white !important;
    text-decoration: none;
    transition: var(--transition-default) color;

    &:hover {
      color: var(--colour-primary) !important;
    }

    html.zd-theme-agents &:hover {
      color: var(--colour-agents) !important;
    }

    html.zd-theme-tenants &:hover {
      color: var(--colour-tenants) !important;
    }
  }

  &-links {
    font-family: var(--font-headings);
    font-size: var(--font-size-large);
    list-style-type: none;
    padding-left: 0;
    width: 100%;

    li {
      display: block;
    }
  }

  &-smallprint {
    flex: 1 1 auto;
    font-size: var(--font-size-tiny);
    margin-top: var(--gutter-large);

    &-1 {
      flex-basis: 25%;
      padding-right: var(--gutter);
    }

    &-2 {
      flex-basis: 50%;
    }

    @media (--large) {
      margin-top: 0;
    }
  }
}
