/**
 * @define RadioGroup
 */

.RadioGroup {
  &-controls {
    display: block;
  }

  /* helpers (errors and hint text) */

  &-helpers {
    font-size: var(--font-size-small);
    max-height: 0;
    overflow: hidden;
    position: relative;
    transition: max-height var(--transition-default);
    z-index: 0;

    &::after {
      border-radius: 0 0 var(--border-radius) var(--border-radius);
      content: var(--unicode-nbsp);
      display: block;
      font-family: var(--font-headings);
      font-size: var(--font-size-small);
      font-weight: normal !important;
      left: 0;
      line-height: 1em;
      padding: var(--gutter-smaller) 0;
      pointer-events: none;
      transition: opacity var(--transition-default);
      width: 100%;
    }

    &[data-hint]:not([data-hint=""]) {
      max-height: 50px;

      &::after {
        color: var(--colour-grey-mid-dark);
        content: attr(data-hint);
      }
    }
  }

  &.is-error,
  html.zd-theme-tenants &.is-error,
  html.zd-theme-agents &.is-error {
    .RadioGroup-input {
      border-color: var(--colour-error);
    }

    .RadioGroup-helpers[data-error]:not([data-error=""]) {
      max-height: 50px;

      &::after {
        color: var(--colour-error);
        content: attr(data-error);
      }
    }
  }
}
