/**
* @define LogoCarousel
*/

.LogoCarousel {
  &--grey {
    background-color: var(--colour-grey-hooktext);
  }

  &-contents {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: var(--gutter-xlarge);
    padding-top: var(--gutter-xlarge);

    @media (--medium) {
      padding-bottom: var(--gutter-xxlarge);
      padding-top: var(--gutter-xxlarge);
    }
  }

  &-header {
    flex: 2 0 auto;
  }

  &-title {
    font-family: var(--font-headings);
    font-size: var(--font-size-hook);
    text-align: center;
    text-transform: uppercase;

    @media (--medium) {
      font-size: var(--font-size-hook-desktop);
    }
  }

  &-subtitle {
    font-size: var(--font-size-normal);
    text-align: center;
  }

  &-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--gutter-xlarge) 0;
    width: 100%;

    @media (--medium) {
      flex-direction: row;
    }
  }

  &-item {
    flex: 0 1 auto;
    margin: 0 var(--gutter);
    padding: 0 var(--gutter);

    &-img {
      max-height: 40px;
      max-width: 160px;
    }

    a& {
      transition: var(--transition-default) transform;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  &-separator {
    height: 40px;
    width: 2px;

    @media (--medium) {
      background-color: var(--colour-grey-lighter);
    }
  }
}
