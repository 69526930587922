/**
* @define InfoTable
*/

.InfoTable {
  &-contents {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: var(--gutter-xlarge);
    padding-top: var(--gutter-xlarge);

    @media (--large) {
      flex-wrap: none;
      padding-bottom: var(--gutter-xxlarge);
      padding-top: var(--gutter-xxlarge);
    }
  }

  &-title {
    flex: 1 0 100%;
    font-family: var(--font-headings);
    font-size: var(--font-size-hook);
    text-transform: uppercase;

    @media (--large) {
      flex-basis: 50%;
      font-size: var(--font-size-hook-desktop);
      padding-right: var(--gutter-xxxlarge);
    }
  }

  &-body {
    align-items: flex-start;
    display: flex;
    flex: 1 0 100%;
    flex-direction: column;
    justify-content: space-between;
    margin-top: var(--gutter-large);

    @media (--large) {
      flex-basis: 50%;
      margin-top: 4px;
    }
  }

  &-row {
    align-self: stretch;

    &:not(:first-child) {
      border-top: 1px solid var(--colour-grey-lighter);
      margin-top: var(--gutter-large);
      padding-top: var(--gutter-large);
    }
  }

  &-row-title {
    font-family: var(--font-headings);
    text-transform: uppercase;
  }

  &-row-body {
    display: flex;
    justify-content: space-between;
    margin-top: var(--gutter);

    @media (--large) {
      margin-top: var(--gutter-xlarge);
    }
  }

  &-row-copy {
    flex: 2 1 auto;
    margin-right: var(--gutter);

    :first-child {
      margin-top: 0;
    }
  }

  &-graphic {
    align-items: center;
    border: 1px solid var(--colour-grey-lighter);
    border-top: 2px solid var(--colour-primary);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex: 0 0 100px;
    flex-direction: column;
    font-family: var(--font-headings);
    font-size: var(--font-size-large);
    justify-content: center;
    padding: var(--gutter) var(--gutter-smaller);
    text-align: center;
    text-transform: uppercase;

    html.zd-theme-agents & {
      border-top-color: var(--colour-agents) !important;
    }

    html.zd-theme-tenants & {
      border-top-color: var(--colour-tenants) !important;
    }

    @media (--large) {
      flex-basis: 120px;
      padding: var(--gutter);
    }
  }
}
