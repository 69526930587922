/**
 * @define Hero
 */

.Hero {
  align-items: flex-end;
  background-size: cover;
  display: flex;
  justify-content: center;

  &-contents {
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    max-height: 500px;
    min-height: 400px;
    min-width: 100vw;
    position: relative;
    width: 100%;

    @media (--large) {
      height: 50vw;
      justify-content: space-between;
      max-height: 600px;
      min-width: auto;
      padding-bottom: 0;
    }

    @media (--ie11) {
      margin: 0;
    }
  }

  &-text {
    display: flex;
    flex: 0 1 75%;
    flex-direction: column;
    font-size: var(--font-size-hero);
    justify-content: center;
    line-height: 1em;
    position: relative;
    z-index: 6;

    @media (--medium) {
      flex-basis: 55%;
    }

    @media (--large) {
      flex-basis: 45%;
      font-size: var(--font-size-hero-desktop);
    }

    &-highlight {
      color: var(--colour-primary);

      html.zd-theme-agents & {
        color: var(--colour-agents) !important;
      }

      html.zd-theme-tenants & {
        color: var(--colour-tenants) !important;
      }
    }
  }

  &-title {
    font-family: var(--font-headings);
    text-transform: uppercase;
  }

  &-copy {
    color: var(--colour-brand);
    font-size: var(--font-size-hero-copy);
    line-height: 1.5em;
    margin-top: var(--gutter);

    @media (--large) {
      width: 80%;
    }
  }

  &-image {
    bottom: 0;
    opacity: 0.2;
    position: absolute;
    right: -30vw;
    width: 80vw;
    z-index: 5;

    @media (--medium) {
      opacity: 1;
      right: -5vw;
      width: 50vw;
    }

    @media (--large) {
      align-self: flex-end;
      flex: 1 1 50%;
      position: relative;
      width: 50%;
    }
  }
}
