/**
 * @define TextArea
 */

.TextArea {
  backface-visibility: hidden;
  display: block;
  overflow: hidden;
  padding-top: 15px;
  position: relative;
  transform: translateZ(0);
  vertical-align: top;
  width: 300px;

  &.is-focused {
    transform: none;
  }

  &-field {
    font-size: 0;
    position: relative;
    transform: translateZ(0);
  }

  &-textarea {
    @mixin inputSimple;

    appearance: none;
    border: 0;
    border-bottom: 1px var(--colour-grey-mid) solid;
    border-radius: 0;
    box-sizing: border-box;
    caret-color: var(--colour-primary);
    color: var(--colour-brand);
    cursor: pointer;
    font-family: var(--font-body);
    font-size: var(--font-size-textarea);
    height: 40px;
    line-height: 1.1;
    margin: 0;
    min-height: var(--input-min-height);
    padding: var(--gutter-small) 0 var(--gutter-small);
    position: relative;
    resize: none;
    width: 100%;
    z-index: 1;

    &:focus {
      cursor: text;
      outline: none;
    }

    &::placeholder {
      color: var(--colour-grey-mid);
      transition: transform 0.3s ease, opacity 0.3s ease;
    }

    html.zd-theme-tenants &:not(:disabled) {
      border-bottom-color: var(--colour-tenants);
      caret-color: var(--colour-tenants);
    }

    html.zd-theme-agents &:not(:disabled) {
      border-bottom-color: var(--colour-agents);
      caret-color: var(--colour-agents);
    }

    html.zd-theme-lld &:not(:disabled) {
      border-bottom-color: var(--colour-lld);
      caret-color: var(--colour-lld);
    }
  }

  &:not(&--present) &-textarea:disabled {
    color: var(--colour-input-disabled);
  }

  &-label {
    color: var(--colour-grey-mid);
    display: block;
    font-size: var(--font-size-small);
    left: 0;
    line-height: 1;
    opacity: 0;
    position: absolute;
    top: -12px;
    transform: translateY(5px);
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  &-border {
    backface-visibility: hidden;
    background-color: var(--colour-grey-dark);
    bottom: 0;
    display: block;
    height: 2px;
    left: 0;
    pointer-events: none;
    position: absolute;
    transform: scaleX(0) translateZ(0);
    transform-origin: 0 0;
    transition: all 0.2s ease-out;
    width: 100%;
    z-index: 2;

    html.zd-theme-tenants & {
      background-color: var(--colour-tenants);
    }

    html.zd-theme-agents & {
      background-color: var(--colour-agents);
    }

    html.zd-theme-lld & {
      background-color: var(--colour-lld);
    }
  }

  &-textarea:invalid {
    box-shadow: none;
  }

  &-textarea:focus::placeholder {
    opacity: 0;
    transform: translateY(-5px);
  }

  &-textarea:not(:disabled):hover ~ &-border {
    background-color: var(--colour-grey-dark);
    transform: scaleX(1) translateZ(0);

    html.zd-theme-tenants & {
      background-color: var(--colour-tenants);
    }

    html.zd-theme-agents & {
      background-color: var(--colour-agents);
    }

    html.zd-theme-lld & {
      background-color: var(--colour-lld);
    }
  }

  &-textarea:not(:disabled):focus ~ &-border {
    background-color: var(--colour-primary);
    transform: scaleX(1) translateZ(0);

    html.zd-theme-agents & {
      background-color: var(--colour-agents);
    }

    html.zd-theme-tenants & {
      background-color: var(--colour-tenants);
    }

    html.zd-theme-lld & {
      background-color: var(--colour-lld);
    }
  }

  &-textarea:focus ~ &-label {
    color: var(--colour-primary);
    opacity: 1;
    transform: translateY(0);

    html.zd-theme-agents & {
      color: var(--colour-agents);
    }

    html.zd-theme-tenants & {
      color: var(--colour-tenants);
    }

    html.zd-theme-lld & {
      color: var(--colour-lld);
    }
  }

  &-textarea:not(:placeholder-shown) ~ &-label {
    opacity: 1;
    transform: translateY(0);
  }

  /* helpers (errors and hint text) */

  &-helpers {
    backface-visibility: hidden;
    font-size: var(--font-size-small);
    letter-spacing: -0.02em;
    position: relative;
    transform: translateZ(0);

    &::after {
      border-radius: 0 0 var(--border-radius) var(--border-radius);
      color: var(--colour-grey-mid);
      content: var(--unicode-nbsp);
      display: block;
      font-size: var(--font-size-small);
      left: 0;
      line-height: 1.2em;
      padding: var(--gutter-small) 0;
      pointer-events: none;
      transition: opacity var(--transition-default);
      width: 100%;
    }

    &[data-hint]:not([data-hint=''])::after {
      content: attr(data-hint);
    }

    &[data-hint]:not([data-hint='']) {
      margin-bottom: var(--gutter);
    }
  }

  &.is-error,
  html.zd-theme-tenants &.is-error,
  html.zd-theme-agents &.is-error,
  html.zd-theme-lld &.is-error {
    .TextArea-textarea {
      border-color: var(--colour-error);
      caret-color: var(--colour-error);
    }

    .TextArea-label {
      color: var(--colour-error);
    }

    .TextArea-border {
      background-color: var(--colour-error) !important;
    }

    .TextArea-helpers::after {
      color: var(--colour-error);
      font-family: var(--font-headings);
      font-weight: normal !important;
      text-align: right;
    }

    .TextArea-helpers[data-error]:not([data-error=''])::after {
      content: attr(data-error);
    }
  }

  &-hiviz {
    overflow: initial;
  }

  &-hiviz.is-error {
    .TextArea-helpers {
      margin-bottom: var(--gutter);
    }

    .TextArea-helpers::after {
      background-color: var(--colour-error);
      border-radius: var(--border-radius-toast);
      box-sizing: border-box;
      color: var(--colour-white) !important;
      font-size: var(--font-size-normal);
      padding: var(--gutter-medium);
      padding-bottom: calc(var(--gutter-medium) - 2px);
      text-align: left !important;
      transform: translateY(var(--gutter-small));
    }

    .TextArea-helpers::before {
      border-bottom: 5px solid var(--colour-error);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      content: '';
      display: block;
      height: 0;
      left: var(--gutter-medium);
      position: absolute;
      top: var(--gutter-smaller);
      width: 0;
    }
  }

  /* disabled state */

  &-textarea:disabled + &-label {
    opacity: 1;
    transform: translateY(0);
  }

  &-textarea:disabled + &-border {
    display: none;
  }

  &-textarea:disabled {
    border-style: dashed;
    cursor: not-allowed;
  }

  &--present &-textarea:disabled {
    border-color: var(--colour-present-border);
    color: var(--colour-brand);
    cursor: default;
    opacity: 1;
    -webkit-text-fill-color: var(--colour-brand);
  }

  /* spanning container and label positioning */

  &--span {
    flex: 2 1 auto;
    width: 100%;

    .TextArea-field,
    .TextArea-textarea {
      width: 100%;
    }
  }
}
