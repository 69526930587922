.TextWell {
  background-color: var(--colour-grey-table-hover);
  border: 1px solid  var(--colour-grey-light);
  border-radius: var(--border-radius-toast);
  display: block;
  margin-top: var(--gutter);
  max-height: 500px;
  opacity: 1;
  overflow: hidden;
  padding: var(--gutter);
  transition: all var(--transition-default);

  &--hidden {
    border-color: transparent;
    max-height: 0;
    opacity: 0;
    padding: 0 var(--gutter);
  }
}
