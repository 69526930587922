/**
 * @define Header
 */

.Header {
  background-color: var(--colour-brand);
  color: white;
  flex: 0 0 auto;
  position: relative;
  width: 100%;

  &-contents {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &-help {
    @media (--medium) {
      position: absolute;
      right: calc(100% + var(--gutter));
      top: 50%;
      transform: translateY(-50%);
      white-space: nowrap;
    }
  }

  &-contact {
    margin-left: var(--gutter);
    padding-left: var(--gutter);
    position: relative;

    a {
      font-family: var(--font-headings);
      font-weight: normal !important;
      text-decoration: none;
    }

    &::before {
      background-color: white;
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      top: 0;
      width: 1px;
    }

    @media (--medium) {
      &::before {
        bottom: -15px;
        top: -15px;
      }
    }
  }
}
