@define-mixin buttonSizing {
  min-width: 130px;
  padding: var(--button-padding);
  white-space: nowrap;

  @media (--medium) {
    min-width: 150px;
  }

  @media (--large) {
    min-width: 180px;
  }
}

@define-mixin buttonBoxShadow $color {
  box-shadow: 0 0 0 3px color($(color) a(50%));
}

@define-mixin buttonInteraction {
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  /* anchor button */

  a& {
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }
}

/* extended hitarea */

@define-mixin buttonHitarea {
  &::after {
    background-color: rgba(0, 0, 0, 0);
    bottom: -10px;
    content: '';
    left: -10px;
    position: absolute;
    right: -10px;
    top: -10px;
  }
}

@define-mixin button $name {
  .$(name) {
    @mixin buttonSizing;

    @mixin buttonInteraction;

    background-color: white;
    border: var(--colour-grey-mid) 1px solid;
    border-radius: var(--border-radius-button);
    box-shadow: 0 0 0 0 color(white a(0%));
    box-sizing: border-box;
    display: inline-block;
    font-family: var(--font-headings);
    font-size: var(--font-size-normal);
    overflow: hidden;
    position: relative;
    text-align: center;
    transition: background-color var(--transition-default),
      box-shadow var(--transition-default);
    vertical-align: bottom;

    &[class*='Icons-'] {
      &::before {
        margin-right: 8px;
        vertical-align: top;
      }

      &::after {
        margin-left: 8px;
        vertical-align: top;
      }
    }

    /* state */

    &:hover {
      border-color: var(--colour-grey-mid-dark);
    }

    &:focus {
      @mixin buttonBoxShadow var(--colour-grey-mid);
    }

    &:active {
      box-shadow: unset;
    }

    /* ripple */

    &-ink {
      background-color: var(--colour-grey-light);
      border-radius: 50%;
      color: red;
      display: block;
      opacity: 0.4;
      pointer-events: none;
      position: absolute;
      transform: scale(0) translateZ(0);

      &.animate {
        animation: ripple 0.6s ease;
      }
    }

    /* modifiers */

    &--primary,
    &--primary-forced,
    &--tertiary,
    &--tenants,
    &--agents {
      background-color: var(--colour-brand);
      border-color: var(--colour-brand);

      &:hover {
        background-color: color(var(--colour-brand) lightness(20%));
        border-color: color(var(--colour-brand) lightness(20%));
      }

      .$(name)-ink {
        background-color: color(white a(70%));
      }
    }

    html.zd-theme-tenants & {
      color: var(--colour-tenants);
    }

    html.zd-theme-agents & {
      color: var(--colour-agents);
    }

    html.zd-theme-lld & {
      color: var(--colour-lld);
    }

    &--primary,
    &--primary-forced {
      color: var(--colour-primary-button) !important;
    }

    &--tertiary {
      color: white !important;
    }

    &--tenants,
    html.zd-theme-tenants &--primary {
      color: var(--colour-tenants-button) !important;
    }

    &--agents,
    html.zd-theme-agents &--primary {
      color: var(--colour-agents-button) !important;
    }

    html.zd-theme-lld &--primary,
    &--lld {
      background-color: var(--colour-lld-button);
      border-color: var(--colour-lld-button);

      color: white !important;
    }

    &--link {
      background-color: transparent;
      border: 0;

      &:focus {
        background-color: white;
        box-shadow: none;
      }

      &:hover {
        color: var(--colour-primary);
        text-decoration: underline;

        html.zd-theme-tenants & {
          color: var(--colour-tenants);
        }

        html.zd-theme-agents & {
          color: var(--colour-agents);
        }
      }
    }

    /* size modifiers */

    &--large {
      font-size: 18px;
      min-width: 210px;
      padding: var(--button-padding-large);
    }

    &--small {
      font-size: 13px;
      min-width: 100px;
      padding: var(--button-padding-small);

      @media (--medium) {
        min-width: 125px;
      }

      @media (--large) {
        min-width: 150px;
      }
    }

    &--span {
      flex: 2 0 auto;
      width: 100%;
    }

    &--flex-half {
      flex: 2 1 100%;

      &:not(:first-child) {
        margin-top: var(--gutter-small);
      }

      @media (--large) {
        flex-basis: 50%;
        margin-right: var(--gutter-small);
        margin-top: 0 !important;
      }
    }

    &:disabled,
    &.is-disabled {
      cursor: not-allowed;
      opacity: 0.8;
    }

    a&.is-disabled {
      pointer-events: none;
    }

    /* margins for series of buttons */

    &:not(:last-child):not(.u-block):not(.Button--flex-half) {
      margin-right: var(--gutter-small);
    }
  }
}

@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5) translateZ(0);
  }
}
