/**
 * @define DocumentIcons
 */

.DocumentIcons {
  font-size: 0;
  width: 100%;

  @media (--large) {
    border-bottom: var(--colour-brand) 2px solid;

  }

  &-item {
    background-color: var(--colour-grey-lighter);
    color: var(--colour-brand) !important;
    cursor: pointer;
    display: inline-block;
    font-size: initial;
    padding: var(--gutter);
    text-align: center;
    text-decoration: none;
    transition: all var(--transition-default);
    width: 50%;

    &:hover {
      background-color: var(--colour-grey-light);
    }

  }

  &-icon {
    display: block;
    padding: 10px 0 0;
  }

  &-label {
    display: block;
  }
}
