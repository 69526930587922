/**
 * @define ProgressStep
 */

.ProgressStep {
  color: var(--colour-grey-copy);
  cursor: default;
  float: right;
  font-size: 0;
  margin: 0.1em 0 0 var(--gutter-small);
  user-select: none;
  vertical-align: top;
  white-space: nowrap;

  &-label {
    display: inline-block;
    font-size: var(--font-size-large);
    line-height: 1.3;
    text-transform: uppercase;
    vertical-align: top;
  }

  &-position {
    display: inline-block;
    font-family: var(--font-headings);
    font-size: 38px;
    font-weight: normal !important;
    line-height: 1;
    margin-left: var(--gutter-smaller);
    vertical-align: top;
  }

  &-total {
    color: var(--colour-grey-mid);
    display: inline-block;
    font-size: calc(var(--font-size-large) + 2);
    line-height: 1.3;
    vertical-align: top;
  }
}
