/**
* @define KeyFeatures
*/

.KeyFeatures {
  &--grey {
    background-color: var(--colour-grey-hooktext);
  }

  &-contents {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: var(--gutter-xlarge);
    padding-top: var(--gutter-xlarge);

    @media (--large) {
      flex-direction: row;
      flex-wrap: nowrap;
      padding-bottom: var(--gutter-xxlarge);
      padding-top: var(--gutter-xxlarge);
    }
  }

  &-item {
    flex: 2 0 100%;
    margin-top: var(--gutter-xlarge);

    @media (--medium) {
      flex-basis: 50%;
      padding-right: var(--gutter-xlarge);
    }

    @media (--large) {
      flex-basis: 30%;
      margin-top: 0;
    }
  }

  &-item-title {
    font-family: var(--font-headings);
    font-size: var(--font-size-large);
    margin-bottom: var(--gutter-large);
    padding-bottom: var(--gutter-large);
    position: relative;
    text-transform: uppercase;

    &::after {
      background-color: var(--colour-primary);
      bottom: 0;
      content: "";
      height: 3px;
      left: 0;
      position: absolute;
      transition: var(--transition-default) width;
      width: 33%;
    }

    html.zd-theme-agents &::after {
      background-color: var(--colour-agents) !important;
    }

    html.zd-theme-tenants &::after {
      background-color: var(--colour-tenants) !important;
    }

    @media (--large) {
      font-size: var(--font-size-large-desktop);
    }
  }

  a&-item {
    color: var(--colour-brand) !important;
    text-decoration: none;

    .KeyFeatures-item-title {
      transition: var(--transition-default) color;
    }

    &:hover .KeyFeatures-item-title {
      color: var(--colour-primary);

      html.zd-theme-agents & {
        color: var(--colour-agents) !important;
      }

      html.zd-theme-tenants & {
        color: var(--colour-tenants) !important;
      }

      &::after {
        width: 80%;
      }
    }
  }

  &-item-copy {
    max-width: 450px;

    @media (--large) {
      max-width: unset;
    }
  }
}
